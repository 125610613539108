import React, { useState, useEffect, useContext } from 'react'
import { useAuth } from '../../app/OAuth/Provider'
import SystemMenu from '../../shared/ui/SystemMenu'
import ToggleGroup from '../../shared/ui/Components/ToggleGroup'
import GroupsProvider from '../../app/Administration/GroupsProvider'
import GroupsContext from '../../app/Administration/GroupsContext'
import { LinearProgress, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import ListChartsIndicators from '../../widgets/ListCharts/ListChartsIndicators'
import TypesProvider from '../../app/Administration/TypesProvider'

function GroupsToggleButtons({ onChanged }) {
  const { dataGroups, loading } = useContext(GroupsContext)

  return loading ? (
    <LinearProgress />
  ) : (
    <ToggleGroup
      name="indicators"
      buttons={dataGroups.map((e) => ({
        key: e.id,
        name: e.name,
      }))}
      onChanged={onChanged}
    />
  )
}

GroupsToggleButtons.propTypes = {
  onChanged: PropTypes.func,
}

function Indicators() {
  const { isAuthenticated, getToken, login } = useAuth()
  const [view, setView] = useState('general')
  const [key, setKey] = useState(0)

  useEffect(() => {
    getToken()
      .then((r) => {})
      .catch((r) => login())
  }, [])

  useEffect(() => {
    if (!isAuthenticated) login()
  }, [isAuthenticated])

  const handleGroupChange = (name, v) => {
    setView(v)
    setKey((prevKey) => prevKey + 1)
  }

  return (
    <SystemMenu>
      <h1>Показатели</h1>
      <GroupsProvider>
        <GroupsToggleButtons onChanged={handleGroupChange} />
      </GroupsProvider>
      <TypesProvider query={{ groupCode: view }}>
        <Stack key={key} direction={'column'}>
          <ListChartsIndicators view={view} />
        </Stack>
      </TypesProvider>
    </SystemMenu>
  )
}

export default Indicators
