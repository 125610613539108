import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import LinearChart from '../../widgets/Chart/LinearChart'
import StatsProvider from '../../app/Stats/StatsProvider'
import ContentPaper from '../../shared/ui/Components/ContentPaper'
import ToggleButtonIndicators from '../ToggleButton/ToggleButtonIndicators'
import ContentToggleButtonLinear from '../ToggleButton/ContentToggleButton/ContentToggleButtonLinear'
import PropTypes from 'prop-types'

const getStartDate = (monthBefore = 1, daysBefore = 0) => {
  const d = new Date()
  d.setDate(d.getDate() - daysBefore)
  d.setMonth(d.getMonth() - monthBefore)
  d.setHours(0, 0, 0, 0)
  return d.getTime() / 1000
}

const ChartSection = ({ title, segment, type, chartType, query, children }) => (
  // пердаю тайп чтобы сейчас не было ошибки для нижнего графика
  <StatsProvider type={type} query={query}>
    <ContentPaper>
      <Box mb={4}>
        <Typography variant="h6">{title}</Typography>
        {children}
        <LinearChart segment={segment} chartType={chartType} />
      </Box>
    </ContentPaper>
  </StatsProvider>
)

ChartSection.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  segment: PropTypes.string,
  chartType: PropTypes.string,
  query: PropTypes.object,
  children: PropTypes.node,
}

function ListChartsInstallations() {
  const [state, setState] = useState({
    period: 'quarter',
    detail: 'day',
    chartType: 'line',
  })

  const handleToggleChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const type = 'installations'
  const query = {
    segment: true,
    segmentField: 'creationDate',
    segmentSize: 2,
    startDate: getStartDate(),
  }

  return (
    <>
      <ChartSection
        title="Количество установок по дням"
        segment="day"
        chartType="bar"
        query={query}
        type={type}
      />

      <ChartSection
        title="Количество активных пользователей"
        segment={state.detail}
        chartType={state.chartType}
        query={{
          segment: state.detail,
          startDate:
            state.period === 'year'
              ? getStartDate(12)
              : state.period === 'quarter'
              ? getStartDate(3)
              : state.period === 'week'
              ? getStartDate(0, 7)
              : getStartDate(1),
        }}
        type={'installations/active'}
      >
        <Box my={2}>
          <ToggleButtonIndicators
            setState={handleToggleChange}
            startState={state}
            buttonsGroupConfig={ContentToggleButtonLinear}
          />
        </Box>
      </ChartSection>
    </>
  )
}

export default ListChartsInstallations
