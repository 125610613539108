import React from 'react'
import { IconButton } from '@mui/material'
import { GetAppRounded } from '@mui/icons-material'
import PropTypes from 'prop-types'
import api from '../api'
import { useAuth } from '../../app/OAuth/Provider'

function DownloadButton({ path }) {
  const { getToken } = useAuth()

  const handleDownload = () => {
    getToken().then((accessToken) => {
      api
        .get(path, {
          authorization: accessToken,
        })
        .then((res) => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(res.blob)
          link.setAttribute('download', res.filename)
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        })
    })
  }

  return (
    <IconButton aria-label={'download'} onClick={() => handleDownload()}>
      <GetAppRounded />
    </IconButton>
  )
}

DownloadButton.propTypes = {
  path: PropTypes.string,
}

function DownloadBuildButton({ id }) {
  return DownloadButton({ path: `/v1/settings/builds/download/${id}` })
}

DownloadBuildButton.propTypes = {
  id: PropTypes.string,
}

export { DownloadButton, DownloadBuildButton }
