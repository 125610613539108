export const parseDate = (dateStr) => {
  const monthFormatter = new Intl.DateTimeFormat('ru-RU', { month: 'long' })
  const yearFormatter = new Intl.DateTimeFormat('ru-RU', { year: 'numeric' })

  if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
    const date = new Date(dateStr)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}-${month}-${year}`
  } else if (/^\d{4}-(0[1-9]|1[0-2])$/.test(dateStr)) {
    const [year, month] = dateStr.split('-')
    const date = new Date(year, month - 1)
    const monthName = monthFormatter.format(date)
    const yearOnly = yearFormatter.format(date)
    return `${monthName} ${yearOnly}`
  } else if (/^\d{4}-(\d{1,2})$/.test(dateStr)) {
    const [year, week] = dateStr.split('-')
    const firstDayOfYear = new Date(year, 0, 1)
    const dayOfWeek = firstDayOfYear.getUTCDay() || 7
    const firstMonday = new Date(firstDayOfYear)
    firstMonday.setDate(firstDayOfYear.getDate() + (8 - dayOfWeek))
    const weekDate = new Date(firstMonday)
    weekDate.setDate(firstMonday.getDate() + (week - 1) * 7)
    const weekNumber = String(week).padStart(2, '0')
    return `${year}-${weekNumber}`
  }
  return dateStr
}

const segmentToDate = (str, segmentType) => {
  const parts = str.split('-')
  const year = parseInt(parts[0], 10)
  switch (segmentType) {
    case 'day':
      if (
        parts.length === 3 &&
        parts[1].length === 2 &&
        parts[2].length === 2
      ) {
        const month = parseInt(parts[1], 10) - 1
        const day = parseInt(parts[2], 10)
        return new Date(year, month, day)
      }
      break
    case 'week':
      if (parts.length === 2 && parts[1].length === 2) {
        const week = parseInt(parts[1], 10)
        const firstDayOfYear = new Date(year, 0, 1)
        const dayOfWeek = firstDayOfYear.getUTCDay() || 7
        const firstMonday = new Date(firstDayOfYear)
        firstMonday.setDate(firstDayOfYear.getDate() + (8 - dayOfWeek))
        const weekDate = new Date(firstMonday)
        weekDate.setDate(firstMonday.getDate() + (week - 1) * 7)
        return weekDate
      }
      break
    case 'month':
      if (parts.length === 2 && parts[1].length === 2) {
        const month = parseInt(parts[1], 10) - 1
        return new Date(year, month, 1)
      }
      break
  }
  return null
}

export const simpleTransformData = (data, step, segmentType) => {
  return data.map((p) => {
    const dt = segmentToDate(p.segment, segmentType)
    return {
      name: parseDate(p.segment),
      timestamp: dt ? dt.getTime() : null,
      count: p.count,
    }
  })
}

export const transformData = (data, step, segmentType) => {
  let start = new Date()
  let end = new Date()

  if (data.length > 0) {
    const startDt = segmentToDate(data[0].segment, segmentType)
    const endDt = segmentToDate(data[data.length - 1].segment, segmentType)
    if (startDt) start = startDt
    if (endDt) end = endDt
  }

  const wideData = simpleTransformData(data, step, segmentType)

  if (wideData.length === 0 || wideData[0].timestamp !== start.getTime()) {
    wideData.unshift({
      name: parseDate(start.toISOString().split('T')[0]),
      timestamp: start.getTime(),
      count: 0,
    })
  }

  if (wideData[wideData.length - 1].timestamp !== end.getTime()) {
    wideData.push({
      name: parseDate(end.toISOString().split('T')[0]),
      timestamp: end.getTime(),
      count: 0,
    })
  }

  let last = null
  const gdata = []

  const getNextTimestamp = (currentTimestamp) => {
    const current = new Date(currentTimestamp)
    switch (segmentType) {
      case 'day':
        return current.setDate(current.getDate() + 1)
      case 'week':
        return current.setDate(current.getDate() + 7)
      case 'month':
        return current.setMonth(current.getMonth() + 1)
      default:
        return current.setDate(current.getDate() + 1)
    }
  }

  for (const item of wideData) {
    if (last !== null) {
      for (
        let t = getNextTimestamp(last);
        t < item.timestamp;
        t = getNextTimestamp(t)
      ) {
        const intermediateDate = new Date(t)
        let segmentLabel
        switch (segmentType) {
          case 'day':
            segmentLabel = intermediateDate.toISOString().split('T')[0]
            break
          case 'week':
            segmentLabel = `${intermediateDate.getUTCFullYear()}-${String(
              Math.ceil((intermediateDate.getUTCDate() + 6) / 7)
            ).padStart(2, '0')}`
            break
          case 'month':
            segmentLabel = `${intermediateDate.getUTCFullYear()}-${String(
              intermediateDate.getUTCMonth() + 1
            ).padStart(2, '0')}`
            break
        }

        gdata.push({
          name: parseDate(segmentLabel),
          timestamp: t,
          count: 0,
        })
      }
    }
    gdata.push(item)
    last = item.timestamp
  }

  return gdata
}
