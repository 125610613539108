import React, { useContext, useRef } from 'react'
import { Box } from '@mui/material'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  Line,
  CartesianGrid,
  Legend,
} from 'recharts'
import StatsContext from '../../app/Stats/StatsContext'
import { useContainerDimensions } from '../../shared/useContainerDimensions'
import PropTypes from 'prop-types'
import CustomTooltip from '../../shared/ui/Components/CustomTooltip'
import { parseDate } from './dateUtils'
import LoadingAndAlertChart from '../../shared/ui/Components/LoadingAndAlertChart.jsx'

const colorForRange = ['#ffcc00', '#aad400', '#c90b0b']

function SegmentedChart({ chartType = 'bar' }) {
  const { data, loading, error } = useContext(StatsContext)

  const ref = useRef(null)
  const { width } = useContainerDimensions(ref)

  const filteredData = data
    .flatMap((rangeItem, rangeIndex) =>
      rangeItem.data.map((entry) => ({
        date: entry.segment,
        [`range${rangeIndex + 1}`]: entry.count,
      }))
    )
    .reduce((acc, curr) => {
      const date = curr.date
      const existingEntry = acc.find((entry) => entry.date === date)

      if (existingEntry) {
        Object.assign(existingEntry, curr)
      } else {
        acc.push(curr)
      }

      return acc
    }, [])
    .sort((a, b) => parseDate(a.date) - parseDate(b.date))

  const formattedData = filteredData.map((entry) => ({
    ...entry,
    date: parseDate(entry.date),
  }))

  const renderLegend = () => (
    <Box display="flex" justifyContent="center" mt={2}>
      {data.map((rangeItem, index) => (
        <Box key={index} display="flex" alignItems="center" mr={3}>
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: colorForRange[index % colorForRange.length],
              display: 'inline-block',
              marginRight: '8px',
            }}
          />
          {rangeItem.range.label}
        </Box>
      ))}
    </Box>
  )

  return (
    <Box ref={ref} sx={{ position: 'relative', minHeight: '300px' }}>
      <LoadingAndAlertChart loading={loading} error={error} />

      {!loading && filteredData.length > 0 && chartType === 'bar' ? (
        <BarChart
          width={width}
          height={300}
          data={formattedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {data.map((_, index) => (
            <Bar
              key={index}
              dataKey={`range${index + 1}`}
              fill={colorForRange[index % colorForRange.length]}
              background={{ fill: 'rgba(0,0,0,0.05)' }}
            />
          ))}
          <Legend content={renderLegend} />
        </BarChart>
      ) : (
        !loading &&
        filteredData.length > 0 && (
          <LineChart
            width={width}
            height={300}
            data={formattedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="date" />
            <YAxis />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Tooltip content={<CustomTooltip />} />
            {data.map((_, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={`range${index + 1}`}
                stroke={colorForRange[index % colorForRange.length]}
                connectNulls
              />
            ))}
            <Legend content={renderLegend} />
          </LineChart>
        )
      )}
    </Box>
  )
}

SegmentedChart.propTypes = {
  chartType: PropTypes.oneOf(['bar', 'line']).isRequired,
}

export default SegmentedChart
