import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import StatsContext from '../../app/Stats/StatsContext.js'
import { useContainerDimensions } from '../../shared/useContainerDimensions.jsx'
import PropTypes from 'prop-types'
import CustomTooltip from '../../shared/ui/Components/CustomTooltip.jsx'
import LoadingAndAlertChart from '../../shared/ui/Components/LoadingAndAlertChart.jsx'
import { simpleTransformData } from './dateUtils'

function LinearChart({ segment, chartType = 'bar' }) {
  const { data, loading, error } = useContext(StatsContext)
  const [segmentSize, setSegmentSize] = useState(24 * 60 * 60 * 1000)

  useEffect(() => {
    switch (segment) {
      case 'day':
        setSegmentSize(24 * 60 * 60 * 1000)
        break
      case 'week':
        setSegmentSize(7 * 24 * 60 * 60 * 1000)
        break
      case 'month':
        setSegmentSize(30 * 24 * 60 * 60 * 1000)
        break
      default:
        setSegmentSize(24 * 60 * 60 * 1000)
    }
  }, [segment])

  // const gdata = transformData(data, step, segment)
  const gdata = simpleTransformData(data, segmentSize, segment)
  const ref = useRef(null)
  const { width } = useContainerDimensions(ref)

  return (
    <Box ref={ref} sx={{ position: 'relative', minHeight: '300px' }}>
      <LoadingAndAlertChart error={error} loading={loading} />

      {!loading && chartType === 'bar' ? (
        <BarChart
          width={width}
          height={300}
          data={gdata}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip type="linear" />} />
          <Bar
            dataKey="count"
            fill="#56c"
            background={{ fill: 'rgba(0,0,0,0.05)' }}
          />
        </BarChart>
      ) : (
        !loading && (
          <LineChart
            width={width}
            height={300}
            data={gdata}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Tooltip content={<CustomTooltip type="linear" />} />
            <Line type="monotone" dataKey="count" stroke="#8884d8" />
          </LineChart>
        )
      )}
    </Box>
  )
}
LinearChart.propTypes = {
  segment: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  chartType: PropTypes.oneOf(['bar', 'line']).isRequired,
}
export default LinearChart
